import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges  } from '@angular/core';

@Component({
  selector: '[subnet-row]',
  template: `
    <td style="width: 40%"><input type="text" [(ngModel)]="subnet.name"></td>
    <td style="width: 20%"><input type="text" [ngModel]="subnet.getCidr()" (ngModelChange)="subnet.setCidr($event)"></td>
    <td style="width: 30%">{{subnet.getThings() | number:'1.0-1'}}</td>
    <td style="width: 10%"><button (click)="onDeleteClick()" type="button" class="btn btn-danger float-sm-right">Delete</button></td>
  `
})
export class SubnetRow {
  @Input() id:      number;
  @Input() azCount: number;
  @Input() azSize:  number;
  @Input() subnet:  any;
  @Output() delete = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges) {
    for (let propName in changes) {
       switch(propName) {
         case 'azCount':
          this.subnet.setAzCount(this.azCount);

         case 'azSize':
          this.subnet.setAzSize(this.azSize);
       }
    }
  }

  onDeleteClick() {
    this.delete.emit(this.id);
  }
}
