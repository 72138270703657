import { Component, OnChanges, SimpleChange} from '@angular/core';
import { Subnet } from './models/subnet.model'
import { TerraformSubnet } from './models/terraformsubnet.model';
import { TerraformSubnetTuple } from './models/terraformsubnettuple.model'
import Netmask from 'netmask'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title 	        = 'Vpc Calculator';
  azCount         = 3;
  azSize          = 28;
  totalCidr       = 0;
  totalIps        = 0;
  baseCidr        = '10.0.0.0/16';
  baseCidrOffset  = '';
  importData      = '';
  subnets         = [
    new Subnet('VPC/Subnet', 28, this.azCount, this.azSize, () => this.update()),
    new Subnet('VPC/Subnet', 25, this.azCount, this.azSize, () => this.update()),
    new Subnet('VPC/Subnet', 27, this.azCount, this.azSize, () => this.update()),
  ];

  //TODO: Make export data a class
  exportData: { [id: string] : any; } = [];
  terraformSubnets: TerraformSubnetTuple[] = [];  

  onDeleteSubnet(id: number) {
    this.subnets.splice(id, 1);
    this.update();
  }

  onAddSubnet() {
    this.subnets.push(new Subnet('VPC/Subnet', 22, this.azCount, this.azSize, () => this.update())); 
  }

  onExport() {
    //Empty current export data
    this.exportData = {};

    this.exportData['baseCidr'] = this.baseCidr;
    this.exportData['azCount']  = this.azCount;
    this.exportData['azSize']   = this.azSize;
    this.exportData['subnets']  = [];

    for (var i = 0; i < this.subnets.length; i++) {
      this.exportData['subnets'].push(this.subnets[i].exportJson());
    }
  }

  onTerraformCalc() {
    var subnets  = this.subnets.slice();

    subnets.sort(function(a, b) { 
      return a.getCidr()-b.getCidr();
    });

    var baseIp   = this.baseCidr.substr(0, this.baseCidr.indexOf('/'));
    var baseMask = parseInt(this.baseCidr.substr(this.baseCidr.indexOf('/')+1));
    var nextIp   = this.baseCidrOffset != '' ? this.baseCidrOffset : baseIp;

    //Empty the current results
    this.terraformSubnets = []

    //Iterate the subnets
    for (var i = 0; i < subnets.length; i++) {

      //Setup tuple
      var currentTuple = new TerraformSubnetTuple(subnets[i].getName());
      
      //Calc newBits
      var newBits      = (subnets[i].getCidr() - baseMask);
      
      //Get base address with desired mask
      var netMask      = new Netmask.Netmask(baseIp + '/' + subnets[i].getCidr());
      var counter      = 0;

      //Count offset from base address to nextIp;
      while (!netMask.contains(nextIp)) {
        counter++;
        netMask = netMask.next();
      }

      //Iterate AZs
      for (var ii = 0; ii < this.azCount; ii++) {

        //Add this subnet
        currentTuple.addSubnet(new TerraformSubnet(newBits, counter, netMask.toString())); 
          
        netMask = netMask.next();
        counter++;
      }

      //Push this tuple onto the list
      this.terraformSubnets.push(currentTuple);

      //Set nextIp to the next available IP
      nextIp = netMask.base;
    }
  }

  onImport() {
    var data = JSON.parse(this.importData);

    this.baseCidr = data.baseCidr;
    this.azCount  = data.azCount;
    this.azSize   = data.azSize;
    this.subnets  = [];

    for (var i = 0; i < data.subnets.length; i++) {
      this.subnets.push(
        new Subnet(
          data.subnets[i].Name,
          data.subnets[i].Cidr,
          this.azCount,
          this.azSize,
          () => this.update()
        )
      );
    }
  }

  update() {
    if(this.subnets == undefined)
      return;
    
    var ipCount = 0;
    for (var i = 0; i < this.subnets.length; i++) {
      ipCount += this.subnets[i].getIpCount();
    }

    this.totalIps  = ipCount;    
    this.totalCidr = Math.floor(32 - Math.log2(ipCount));
  }
}
