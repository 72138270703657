export class TerraformSubnet {
  private newBits:  number;
  private netNum: 	number;
  private cidr:     string;


  constructor(newBits: number, netNum: number, cidr: string) {
    this.newBits  = newBits;
  	this.netNum 	= netNum;
    this.cidr     = cidr;
  }

  setNewBits(newBits: number) {
    this.newBits  = newBits;
  }
  getNewBits() {
    return this.newBits;
  }

  setNetNum(netNum: number) {
  	this.netNum   = netNum;
  }
  getNetNum() {
  	return this.netNum;
  }

  setCidr(cidr: string) {
    this.cidr   = cidr;
  }
  getCidr() {
    return this.cidr;
  }
}