export class Subnet {
  private name:     string;
  private cidr: 	  number;
  private things: 	number;
  private azCount: 	number;
  private azSize: 	number;
  private ipCount:	number;
  private callback: () => void;

  constructor(name?: string, cidr?: number, azCount?: number, azSize?: number, callback?: () => void) {
    this.name     = name;
  	this.cidr 	  = cidr;
  	this.azCount  = azCount;
  	this.azSize	  = azSize;
  	this.callback = callback;

  	this.calcThings();
  }

  setName(name: string) {
    this.name = name;
  }
  getName() {
    return this.name;
  }

  setCidr(count: number) {
  	this.cidr = count;
  	this.calcThings();
  }
  getCidr() {
  	return this.cidr;
  }

  getThings() {
  	return this.things;
  }
  getIpCount() {
    return this.ipCount;
  }

  setAzCount(count: number) {
  	this.azCount = count;
  	this.calcThings();
  }
  getAzCount() {
  	return this.azCount;
  }

  setAzSize(count: number) {
  	this.azSize = count;
  	this.calcThings();
  }
  getAzSize() {
  	return this.azSize;
  }

  exportJson() {
    return {
      Name: this.name,
      Cidr: this.cidr,
    }
  }

  calcThings() {
    this.things  = Math.floor(Math.pow(2, this.azSize-this.cidr) / this.azCount);
    this.ipCount = Math.pow(2, 32-this.cidr);
    this.callback();
  }
}