import { TerraformSubnet } from './terraformsubnet.model'

export class TerraformSubnetTuple {
  private name:    string;
  private subnets: TerraformSubnet[];


  constructor(name: string) {
    this.name    = name;
    this.subnets = [];
  }

  setName(name: string) {
    this.name  = name;
  }
  getName() {
    return this.name;
  }

  addSubnet(subnet: TerraformSubnet) {
    this.subnets.push(subnet);
  }
  getSubnets() {
    return this.subnets;
  }
}